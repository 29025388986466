/* eslint-disable no-loop-func */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IntlMessages from "../../../../../utils/IntlMessages";
import {
  getLabel,
  getOverlineText,
  getIntlTranslation,
} from "../../../../../utils/helper";
import {
  Box,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import PlacesAutocomplete from "../../../../../components/locations/PlacesAutocomplete";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, <IntlMessages id="required_field" />)
    .required(<IntlMessages id="required_field" />),
  address: Yup.string().required(<IntlMessages id="required_field" />),
  city: Yup.string().required(<IntlMessages id="required_field" />),
  state: Yup.string().required(<IntlMessages id="required_field" />),
  postalCode: Yup.string().required(<IntlMessages id="required_field" />),
  country: Yup.string().required(<IntlMessages id="required_field" />),
  subAssetType: Yup.object().shape({
    title: Yup.string()
      .min(1, <IntlMessages id="required_field" />)
      .required(<IntlMessages id="required_field" />),
    value: Yup.string()
      .nullable()
      .required(<IntlMessages id="required_field" />)
      .required(<IntlMessages id="required_field" />),
  }),
});

const SubAssetCreateForm = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const [fullAddress, setFullAddress] = useState("");
  const [buildingProfileOptions, setBuildingProfileOption] = useState([]);

  useEffect(() => {
    if (props.buildingType?.length > 0) {
      setBuildingProfileOption(props.buildingType);
    }
  }, [props.buildingType]);

  return (
    <Box
      style={{ overflowY: "auto", marginTop: 5 }}
      className={"custom-scrollBar"}
    >
      <Card>
        <CardContent>
          <Formik
            enableReinitialize
            initialValues={props.intialFormData}
            validationSchema={validationSchema}
            onSubmit={(values) => props.handleSave(values)}
            innerRef={props.formRef}
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              handleChange,
              isSubmitting,
            }) => (
              <>
                <Form>
                  <Grid container justify="center" spacing={1}>
                    <Grid item md={6} xs={12} style={{ marginTop: 10 }}>
                      {getOverlineText("_Sub-Asset Information")}
                      <Field
                        name="name"
                        disabled={props.readOnly}
                        style={{marginTop:10}}
                        label={getLabel("Name")}
                        as={TextField}
                        size="small"
                        dense={"true"}
                        className="grid-fields"
                        error={touched.name && errors.name}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>

                    <Grid item md={6} xs={12} style={{ marginTop: 10 }}>
                      {getOverlineText("_building type")}
                      <Autocomplete
                        freeSolo
                        id={`subAssets.building_type`}
                        size="small"
                        style={{marginTop:10}}
                        value={
                          !!values.subAssetType
                            ? values.subAssetType
                            : ""
                        }
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === "string") {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return option.title;
                        }}
                        selectOnFocus
                        clearOnBlur
                        options={buildingProfileOptions?.filter(
                          (f) => f.language === scope.projectLanguageCode
                        )}
                        getOptionSelected={(option, value) =>
                          value.value === option.value
                        }
                        inputValue={values.subAssetType.title}
                        onInputChange={(event, newInputValue) => {
                          if (typeof newInputValue === "string") {
                            setFieldValue(
                              `subAssetType`,
                              {
                                title: newInputValue,
                                value: newInputValue,
                              }
                            );
                          }
                        }}
                        onChange={(event, newValue) => {
                          if (typeof newValue === "string") {
                            setFieldValue(
                              `subAssetType`,
                              {
                                title: newValue,
                                value: newValue,
                              }
                            );
                          } else if (newValue !== null) {
                            setFieldValue(
                              `subAssetType`,
                              newValue
                            );
                          } else {
                            setFieldValue(
                              `subAssetType`,
                              {
                                title: "",
                                value: null,
                              }
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <>
                            <TextField
                              {...params}
                              label={getLabel("_building type")}
                              name={`subAssetType`}
                              className="grid-fields"
                              size="small"
                              dense="true"
                              error={
                                getIn(
                                  touched,
                                  `subAssetType.value`
                                ) &&
                                getIn(
                                  errors,
                                  `subAssetType.value`
                                )
                              }
                              helperText={
                                getIn(
                                  touched,
                                  `subAssetType.value`
                                ) &&
                                getIn(
                                  errors,
                                  `subAssetType.value`
                                )
                              }
                            />
                          </>
                        )}
                        renderOption={(props, option, { inputValue }) => {
                          const matches = match(option.title, inputValue);
                          const parts = parse(option.title, matches);

                          return (
                            <>
                              <li {...props}>
                                <div>
                                  {parts.map((part, index) => (
                                    <span
                                      key={index}
                                      style={{
                                        color: part.highlight ? "red" : "black",
                                      }}
                                    >
                                      {part.text}
                                    </span>
                                  ))}
                                </div>
                              </li>
                            </>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginTop: 10 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          disabled={props.readOnly}
                          views={["year"]}
                          className="grid-fields"
                          label={getLabel("_Construction Year")}
                          value={!!values.constructionYear ? dayjs(new Date(values.constructionYear,1,1)) : null}
                          onChange={(newValue) => {
                            if(!!newValue){
                              setFieldValue("constructionYear", newValue.year());
                            }
                          }}
                          slotProps={{
                            textField: {
                              variant: "outlined",
                              size: "small",
                              style: {width:"100%"},
                              helperText: null,
                              error: null,
                            },inputAdornment:{
                              position:"start"
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginTop: 10 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          disabled={props.readOnly}
                          views={["year"]}
                          className="grid-fields"
                          label={getLabel("_Renovation Year")}
                          value={dayjs(new Date(values.renovationYear,1,1))}
                          onChange={(newValue) => {
                            if(!!newValue){
                              setFieldValue("renovationYear", newValue.year());
                            }
                          }}
                          slotProps={{
                            textField: {
                              variant: "outlined",
                              style: {width:"100%"},
                              size: "small",
                              helperText: null,
                              error: null,
                            },inputAdornment:{
                              position:"start"
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
                      {getOverlineText("Address Detail")}
                    </Grid>
                    <Grid item md={12} xs={12}>
                      {!props.readOnly && (
                        <>
                          <div style={{ marginLeft: 20, width: "95%" }}>
                            <PlacesAutocomplete
                              setGetAddress={(address) => {
                                setFieldValue("addressLabel", address.label);
                                setFieldValue("address", address.street);
                                setFieldValue("city", address.city);
                                setFieldValue("postalCode", address.postalCode);
                                setFieldValue("state", address.state);
                                setFieldValue("country", address.country);
                              }}
                              fullAddress={fullAddress}
                            />
                          </div>
                        </>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginTop: "10px" }}>
                      <Field
                        disabled={props.readOnly}
                        name="address"
                        label={getLabel("_Address")}
                        as={TextField}
                        size="small"
                        dense={"true"}
                        multiline
                        className="grid-fields"
                        error={touched.address && errors.address}
                        helperText={touched.address && errors.address}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginTop: "10px" }}>
                      <Field
                        disabled={props.readOnly}
                        name="city"
                        label={getLabel("_City")}
                        as={TextField}
                        size="small"
                        dense={"true"}
                        className="grid-fields"
                        error={touched.city && errors.city}
                        helperText={touched.city && errors.city}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginTop: "10px" }}>
                      <Field
                        disabled={props.readOnly}
                        name="postalCode"
                        label={getLabel("_zip-code")}
                        as={TextField}
                        size="small"
                        dense={"true"}
                        className="grid-fields"
                        error={touched.postalCode && errors.postalCode}
                        helperText={touched.postalCode && errors.postalCode}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginTop: "10px" }}>
                      <Field
                        disabled={props.readOnly}
                        name="state"
                        label={getLabel("_State")}
                        as={TextField}
                        size="small"
                        dense={"true"}
                        className="grid-fields"
                        error={touched.state && errors.state}
                        helperText={touched.state && errors.state}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginTop: "10px" }}>
                      <Field
                        disabled={props.readOnlys}
                        name="country"
                        label={getLabel("_Country")}
                        as={TextField}
                        size="small"
                        dense={"true"}
                        className="grid-fields"
                        error={touched.country && errors.country}
                        helperText={touched.country && errors.country}
                      />
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

SubAssetCreateForm.propTypes = {
  intialFormData: PropTypes.object,
  handleSave: PropTypes.func,
  formRef: PropTypes.object,
  buildingType: PropTypes.array,
  editDialog: PropTypes.bool,
};

export default SubAssetCreateForm;
