import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import CustomButton from "../../../../../components/buttons/CustomButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { setTab, setFullMode } from "../keyFindingSlice";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import InternalPrompt from "../../../../../components/prompt/InternalPrompt";
import {
  getIntlTranslation,
  getWarningText,
  getTranslationVariantText,
} from "../../../../../utils/helper";
import { capex, capexPortfolioDetail, capexPortfolioSummary } from "../../../../../utils/defaultValues";
import useAssetListHook from "../../../../component-bundle/hooks/useAssetListHook";
import ReportGenerationDialog from "../../../../component-bundle/generate-report/ReportGenerationDialog";

function CategoriesTab(props) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const state = useSelector((state) => state.tddKeyFinding);
  const openAsset = useSelector((state) => state.openAsset);
  const scope = useSelector((state) => state.container.scope);
  const readOnlystate = useSelector((state) => state.readOnly);
  const [blockChange, setBlockChange] = useState({
    block: false,
    value: null,
    action: "",
  });
  const {assetList,fetchList} = useAssetListHook();

  useEffect(() => {fetchList()},[scope.projectIdent]);
  
  const getReportOptions =  [capex, capexPortfolioDetail, capexPortfolioSummary];

  const getDataList = useMemo(() => assetList.assets?.map((a) => ({
    name: a.name,
    ident: a.assetIdent,
    isParent:true,
    projectIdent: scope.projectIdent,
    parentIdent:a.assetIdent,
    users: a?.userDtos?.map((u) => {
      return {
        label: `${u.firstName} ${u.lastName}`,
        value: u.emailAddress,
      };
    }),
  })), [assetList])

  const getSelectedData = useMemo(() => {
    let found = assetList.assets?.find((a) => a.assetIdent === openAsset.ident);
    if(!!found){
      return ({
        name: found.name,
        ident: found.assetIdent,
        isParent:true,
        parentIdent:found.assetIdent,
        users:found?.userDtos?.map((u) => {
          return {
            label: `${u.firstName} ${u.lastName}`,
            value: u.emailAddress,
          }}),
        projectIdent: scope.projectIdent,
      })
    }
    return null;
  },[assetList, openAsset]);



  useEffect(() => {
    if (!value) {
      setValue(props.categories?.options[0]);
      dispatch(setTab(props.categories?.options[0]));
      props.onTabSelect(props.categories?.options[0]);
    } else if (props.categories?.options[0].parent !== state.tab?.parent) {
      dispatch(
        setTab(
          props.categories?.options.find(
            (f) => f.key === state.tab?.key && f.parent !== state.tab.parent
          )
        )
      );
      props.onTabSelect(
        props.categories?.options.find(
          (f) => f.key === state.tab?.key && f.parent !== state.tab.parent
        )
      );
    } else {
      setValue(props.categories?.options.find((f) => f.key === state.tab?.key));
    }
  }, [dispatch, state.tab, props.categories?.options, value]);

  const handleChange = (event, newValue) => {
    if (state.updatedRows.length > 0) {
      setBlockChange({
        block: true,
        value: newValue,
        action: "tab-change",
      });
    } else {
      dispatch(setTab(newValue));
      setBlockChange({
        block: false,
        value: null,
        action: "",
      });
    }
  };

  const openFullModeCondition = () => {
    if (state.updatedRows.length > 0) {
      setBlockChange({
        block: true,
        action: "open-fullMode",
        value: true,
      });
    } else {
      dispatch(setFullMode(true));
    }
  };

  return (
    <>
      <Box
        style={{ flexGrow: 1, marginBottom: 5 }}
        className="d-flex align-items-center justify-content-start"
      >
        <AppBar
          position="static"
          style={{
            width: "70%",
            height: 40,
            borderRadius: 5,
            backgroundColor: "#ffffff",
          }}
        >
          <Toolbar
            style={{ minHeight: 35, padding: 0, height: 40 }}
            className="d-flex align-items-center w-100"
          >
            <Tabs
              style={{ height: 40, padding: 0, width: "100%", minHeight: 40 }}
              value={value}
              onChange={handleChange}
              className="g-btn-secondary d-flex align-items-center"
              indicatorColor="secondary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#d08f8e",
                  height: 12,
                  borderRadius: 40,
                },
              }}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable categories"
            >
              {props.categories.options?.map((m, index) => {
                return (
                  <Tab
                    style={{
                      color: "#254a9a",
                      padding: 10,
                      height: 40,
                      maxWidth: "fit-content",
                      marginTop: 5,
                    }}
                    label={
                      <Typography variant="button" display="block" gutterBottom>
                        {m.name}
                      </Typography>
                    }
                    key={m.ident}
                    value={m}
                  />
                );
              })}
            </Tabs>
          </Toolbar>
        </AppBar>
        <Box
          style={{
            display: "flex",
            width: "30%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#d08f8e",
            height: 40,
            borderRadius: 5,
            margin: "0px 4px",
          }}
          aria-label="button group for defect grid"
        >
          <CustomButton
            id="save-defects-changes-rows"
            className="g-btn-elevated"
            buttonText={"_save"}
            style={{
              margin: 5,
              height: 30,
              width: "40%",
              backgroundColor: "#244a9a",
              color: "#ffffff",
            }}
            variant="button"
            tooltip={{
              title: getIntlTranslation("tooltip.save"),
            }}
            handleClick={(e) => props.onSave()}
          />
          <Divider
            orientation="vertical"
            style={{
              width: 4,
              margin: "10px 0px",
              backgroundColor: "#ffffff",
            }}
            flexItem
          />
          <div className="g-btn-elevated" style={{ margin: 2 }}>
          <ReportGenerationDialog
                    reportOptions={getReportOptions}
                    selectedData = { getSelectedData}
                    filterDataList = {getDataList}
                    id={`report-generation`}
                    className="text-light p-1"
                  />
          </div>
          {!state.fullMode && (
            <>
              <Divider
                orientation="vertical"
                style={{
                  width: 4,
                  margin: "10px 0px",
                  backgroundColor: "#ffffff",
                }}
                flexItem
              />
              <div className="g-btn-elevated" style={{ margin: 2 }}>
                <CustomIconButton
                  id="open-full-mode"
                  handleClick={() => openFullModeCondition()}
                  style={{
                    borderRadius: 4,
                    margin: 5,
                    height: 30,
                  }}
                  icon={
                    <FullscreenIcon
                      style={{ width: 22, height: 22, color: "#ffffff" }}
                    />
                  }
                  tooltip={{
                    title: getIntlTranslation("tooltip.fullscreen"),
                  }}
                />
              </div>
            </>
          )}
        </Box>

      </Box>
      {!readOnlystate.isReadOnly && (
        <InternalPrompt
          when={blockChange.block}
          stayText="_back"
          saveText="Save And Leave"
          leaveText="_continue"
          onLeave={() => {
            if (blockChange.action === "tab-change") {
              dispatch(setTab(blockChange.value));
            } else if (blockChange.action === "open-fullMode") {
              dispatch(setFullMode(blockChange.value));
            }
            setBlockChange({
              block: false,
              value: null,
              action: "",
            });
          }}
          onStay={() =>
            setBlockChange({
              block: false,
              value: null,
              action: "",
            })
          }
          warningHeader={getTranslationVariantText("_leave warning", "body1")}
          message={getWarningText(state.updatedRows)}
        />
      )}
    </>
  );
}

CategoriesTab.propTypes = {
  onSave: PropTypes.func,
  categories: PropTypes.object,
  onTabSelect: PropTypes.func,
  checkIfRowsUpdated: PropTypes.func,
};

export default CategoriesTab;
