import axios from "./customAxios";
import { trackPromise } from "react-promise-tracker";
import {
  getAuthTokenSuccess,
  getResetLinkSuccess,
  getValidateTokenSuccess,
  getPasswordSuccess,
} from "../authentication/authSlice";
import {setSystemLanguage} from "../layout/app-container/systemSlice"
import { getErrorFailure,resetErrorState } from "../error/errorHandlingSlice.js";
import { isAuthTokenValid,authHeader,getCurrentUser,getUserData } from "../authentication/authTokenService";
import { getAuth, signInWithCustomToken } from "firebase/auth";

export const authenticateUser = (authMe) => async (dispatch) => {
  try {
    const response = await trackPromise(
      axios.post(`/auth/login`, {
        headers: {
          Accept: "application/json",
        },
        usernameOrEmail: authMe.email,
        password: authMe.password,
      })
    );
    if (!!response.data.accessToken) {
      let payload = getUserData(response.data);
      const isAuthSuccess = await authenticateWithFireBase(payload.fireBaseAuth);
      dispatch(getAuthTokenSuccess({...payload, isFireBaseAuth: isAuthSuccess}));
      dispatch(setSystemLanguage(payload.language));
      dispatch(resetErrorState());
      
    } else {
      let payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: "",
        responseError: true,
      };
    }
  } catch (error) {
    let payload = {};
    if (error.response) {
      payload = {
        type: "ERROR",
        messages:
          " Login Failed! The credentials specified are invalid. Please try again or you can reset your password",
      error: error?.response?.data,
        responseError: true,
      };
      dispatch(getErrorFailure(payload));
    } else {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const sendPasswordResetLink = (email) => async (dispatch) => {
  try {
    const response = await trackPromise(
      axios.post(`/auth/reset`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },

        type: "emailDto",
        email,
      })
    );
    dispatch(getResetLinkSuccess(response.data));
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};

export const validateResetToken = (token) => async (dispatch) => {
  try {
    const response = await trackPromise(
      axios.get(`/auth/validate-reset-token/${token}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
    );
    dispatch(getValidateTokenSuccess(response.data));
  } catch (error) {
    if (error.response || error.request) {
      let payload = {};
      payload = {
        type: "ERROR",
        messages:
          "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
      error: error?.response?.data,
        responseError: "true",
      };
      dispatch(getErrorFailure(payload));
    }
  }
};
export const resetPassword =
  (token, password, confirmPassword) => async (dispatch) => {
    try {
      const response = await trackPromise(
        axios.post(`/auth/reset-password`, {
          type: "passwordDto",
          token: token,
          newPassword: password,
        })
      );
      dispatch(getPasswordSuccess(response.data));
    } catch (error) {
      if (error.response || error.request) {
        let payload = {};
        payload = {
          type: "ERROR",
          messages:
            "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
        error: error?.response?.data,
          responseError: "true",
        };
        dispatch(getErrorFailure(payload));
      }
    }
  };
export const renewAccessTokenIfGoingExpire = (dispatch) => {
  return new Promise((resolve, reject) => {
    const user = getCurrentUser();
    if (!isAuthTokenValid(user.data.token.accessToken)) {
      trackPromise(
        axios
          .post(`/auth/renewAccessToken`, {
            userId: user.uid,
            refreshToken: user.data.token.refreshToken,
          })
          .then((response) => {
            let payload = getUserData(response.data);
            dispatch(getAuthTokenSuccess(payload));
            return resolve(authHeader());
          })
          .catch((error) => {
            let payload = {};
            payload = {
              type: "ERROR",
              messages:
                "OOPS! Something might have went wrong, Please try again.If the issue still exists, Please contact guru@taeurope.com",
            error: error?.response?.data,
              responseError: "true",
            };
            dispatch(getErrorFailure(payload));

            return reject(error);
          })
      );
    } else {
      return resolve(authHeader());
    }
  });
};


const authenticateWithFireBase = async (fireBaseAuth) => {
  const auth = getAuth();
  try {
    const userCredential = await signInWithCustomToken(auth,fireBaseAuth);
    // Firebase sign-in successful, you can update the state as needed
    localStorage.setItem('fToken', fireBaseAuth);
   return true;
  } catch (error) {
    // Handle Firebase authentication errors
    
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error("Firebase authentication failed", errorCode, "-----", errorMessage);
    return false;
    // Optionally, dispatch an error action or handle the error based on your application's needs
  }
}

export const getFireBaseCustomToken = () => async (dispatch) => {
  try {
      const tokenHeader = await renewAccessTokenIfGoingExpire();
      const response = await axios({
        method: "post",
        url: "/user/firebase-token",
        headers: {
          Authorization: tokenHeader,
        },
      })
      localStorage.setItem('fToken', response.data);
  } catch (error) {
      console.error("Error logging in:", error);
  }
};
