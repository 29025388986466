import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import CustomButton from "../../../../components/buttons/CustomButton";
import CreateProjectForm from "../../create-project/structure/CreateProjectForm";
import AddUsersAndRole from "../../create-project/structure/AddUsersAndRole";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IntlMessages from "../../../../utils/IntlMessages";
import { getVariantText, getTranslationVariantText, getDialogBoxHeader } from "../../../../utils/helper";

import {
  Toolbar,
  Divider,
  Typography,
  AppBar,
  Box,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CustomIconButton from "../../../../components/buttons/icons-buttons/CustomIconButton";
import DeleteIconButton from "../../../../components/buttons/icons-buttons/DeleteIconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteConfirmationAlert from "../../../../components/alerts/DeleteConfirmationAlert";
import { deleteProject } from "../../../../api/openProjectApi";

const validationSchema = Yup.object().shape({
  projectName: Yup.string()
    .min(1, <IntlMessages id="required_field" />)
    .required(<IntlMessages id="required_field" />),
  projectNumber: Yup.string().required(<IntlMessages id="required_field" />),
  projectDescription: Yup.string().required(<IntlMessages id="required_field" />),
  language: Yup.string().nullable().required(<IntlMessages id="required_field" />),
  clientName: Yup.string().required(<IntlMessages id="required_field" />),
  clientAddress: Yup.string().required(<IntlMessages id="required_field" />),
  clientCity: Yup.string().required(<IntlMessages id="required_field" />),
  clientZip: Yup.string().required(<IntlMessages id="required_field" />),
  clientCountry: Yup.string().required(<IntlMessages id="required_field" />),
  projectThreshold: Yup.number().required(<IntlMessages id="required_field" />),
});

const ProjectDashboardContainer = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const dispatch = useDispatch();
  const formRefProject = useRef();
  const formRefUsers = useRef();
  const [markDelete, setMarkDelete] = useState(false);
  const [userPermission, setUserPermission] = useState({
    delete: false,
    update: false,
    read: false,
    create: false,
  });

  const [disabled, setDisabled] = useState(true);
  const [intialUserFormData, setIntialUserFormData] = useState({
    users: {},
  });

  const [intialFormData, setIntialFormData] = useState({
    projectName: "",
    projectNumber: "",
    startDate: null,
    endDate: null,
    isUsersAdded: false,
    users: {},
    language: "",
    projectDescription: "",
    clientName: "",
    clientIdent: "",
    clientContactPerson: "",
    clientAddress: "",
    clientStreet2: "",
    clientCity: "",
    clientState: "",
    clientZip: "",
    clientCountry: "",
    serviceLine: props.serviceLine.name,
    projectThreshold: 10000, // Default value for new projects
  });

  useEffect(() => {
    if (!!props.data && Object.keys(props.data).length > 0) {
      const client = props.data.clientData;
      const address = client.addressData;
      let projectThreshold = props.data.projectThreshold;
      if (projectThreshold === null || projectThreshold === undefined) {
        projectThreshold = 10000;
      } else if (projectThreshold === 0) {
        projectThreshold = 0;
      }
      let intialFormData = {
        projectName: props.data.projectName,
        projectNumber: props.data.projectNumber,
        startDate: props.data.startDate,
        endDate: props.data.endDate,
        language: props.data.projectLanguage,
        projectDescription: props.data.projectDescription,
        clientName: client.clientName,
        clientIdent: client.clientIdent,
        clientContactPerson: "",
        clientAddress: address.streetName,
        clientStreet2: "",
        clientCity: address.city,
        clientState: address.state,
        clientZip: address.postalCode,
        clientCountry: address.country,
        serviceLine: props.serviceLine.name,
        projectThreshold: projectThreshold, // Use existing value or default
      };
      setIntialFormData(intialFormData);
      let tempObj = {};
      props.data.roles.forEach((e) => {
        tempObj[e] = props.data.projectUserDtos
          .map((m) => {
            let found = m.projectRolesList.find((s) => e === s.name);
            if (!!found) {
              return {
                user: {
                  firstName: m.firstName,
                  lastName: m.lastName,
                  emailAddress: m.emailAddress,
                },
                role: e,
              };
            } else {
              return null;
            }
          })
          .filter((f) => !!f);
      });
      setIntialUserFormData({
        users: tempObj,
      });
      if (!!props.userPermission) {
        setUserPermission(props.userPermission);
      }
    }
  }, [props.data, props.serviceLine, props.userPermission]);

  const handleSave = (values) => {
    const address = {
      type: "addressDto",
      streetName: values.clientAddress,
      optionalStreetName: values.clientStreet2,
      postalCode: values.clientZip,
      city: values.clientCity,
      state: values.clientState,
      country: values.clientCountry,
    };
    const clientData = {
      type: "clientDto",
      clientName: values.clientName,
      clientIdent: values.clientIdent,
      addressData: address,
    };

    const projectThreshold = parseFloat(values.projectThreshold).toFixed(2);

    const project = {
      type: "projectsDto",
      projectName: values.projectName,
      projectNumber: values.projectNumber,
      projectDescription: values.projectDescription,
      projectOwner: values.owner,
      projectLanguage: values.language,
      startDate: values.startDate,
      endDate: values.endDate,
      projectIdent: props.data.projectIdent,
      clientData: clientData,
      serviceLine: values.serviceLine,
      projectThreshold: projectThreshold, // Ensure value is sent as decimal with 2 decimal places
    };
    props.updateProject(project);
  };

  const saveProjectUsers = (values) => {
    const userRoleList = Object.keys(values.users)
      ?.map((key) => values.users[key])
      .flat(1);
    let temp = [];
    userRoleList?.forEach((e) => {
      let index = temp.findIndex(
        (item) => item.emailAddress === e.user.emailAddress
      );
      if (index >= 0) {
        temp[index] = {
          ...temp[index],
          projectRolesList: [
            ...temp[index].projectRolesList,
            ...[
              {
                type: "projectRolesDto",
                name: e.role,
              },
            ],
          ],
        };
      } else {
        const projectUserDto = {
          type: "projectUserDto",
          firstName: e.user.firstName,
          lastName: e.user.lastName,
          emailAddress: e.user.emailAddress,
          projectRolesList: [
            {
              type: "projectRolesDto",
              name: e.role,
            },
          ],
        };
        temp.push(projectUserDto);
      }
    });
    const project = {
      type: "projectsDto",
      projectIdent: props.data.projectIdent,
      projectUserDtos: temp,
    };
    props.updateProjectUsers(project);
  };

  const getWarningText = () => {
    return (
      <>
        <List>
          <ListItem key={"project-delete-key"} style={{ padding: 0 }}>
            <ListItemText
              id={"delete_project_warning"}
              primary={
                <div className="d-flex align-items-center">
                  {getTranslationVariantText("Project Name", "subtitle2", {
                    fontWeight: "bold",
                    color: "#254a9a",
                  })}
                  {getVariantText(
                    ` : ${props.data?.projectName}`,
                    "subtitle2",
                    { fontWeight: "bold", color: "#254a9a" }
                  )}
                </div>
              }
            />
          </ListItem>
        </List>
      </>
    );
  };

  return (
    <>
      <div className="d-flex flex-column">
        {!!props.data && Object.keys(props.data).length > 0 && (
          <>
            <Card style={{ height: "50%", margin: 5 }}>
              <CardContent>
                <Formik
                  enableReinitialize
                  initialValues={intialFormData}
                  validationSchema={validationSchema}
                  onSubmit={(values) => handleSave(values)}
                  innerRef={formRefProject}
                >
                  {({
                    values,
                    setFieldValue,
                    errors,
                    touched,
                    handleChange,
                    isSubmitting,
                  }) => (
                    <>
                      <Form>
                        <>
                          <AppBar className={"appBar g-btn-secondary"}>
                            <Toolbar
                              style={{ minHeight: 40, padding: "0px 8px" }}
                              className="d-flex align-items-center"
                            >
                              {getDialogBoxHeader("project-overview")}
                              <div
                                style={{ marginLeft: "auto", width: "50%" }}
                                className={"d-flex align-items-center"}
                              >
                                <CustomIconButton
                                  id="project_edit"
                                  icon={
                                    <EditIcon
                                      style={{ color: "#244a9a", fontSize: 20 }}
                                    />
                                  }
                                  permission={{ ...userPermission }}
                                  handleClick={(e) => {
                                    setDisabled((prev) => !prev);
                                  }}
                                  tooltip={{
                                    title: "Click To Edit Project",
                                  }}
                                  style={{ marginLeft: "auto" }}
                                  aria-describedby={`project_edit`}
                                />
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  style={{
                                    margin: "0 5px",
                                    backgroundColor: "#d08f8e",
                                  }}
                                />

                                {userPermission.delete && (
                                  <>
                                    <DeleteIconButton
                                      id={scope.isProjectScope}
                                      handleClick={(e) =>
                                        setMarkDelete(true)
                                      }
                                      color={"#f50057"}
                                      tooltip={{
                                        title: "Mark project Deleted",
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            </Toolbar>
                          </AppBar>
                          <CreateProjectForm
                            values={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            readOnly={disabled}
                          />
                        </>
                      </Form>
                    </>
                  )}
                </Formik>
              </CardContent>
              {!disabled && (
                <CardActions style={{ marginTop: "auto" }} className="w-100">
                  <CustomButton
                    id="create-project-button"
                    buttonText="_save"
                    variant="button"
                    style={{ marginLeft: "auto", width: "10%", height: 40 }}
                    tooltip={{
                      title: "click to save a project",
                    }}
                    handleClick={(e) => formRefProject.current.handleSubmit()}
                  />
                </CardActions>
              )}
            </Card>
            <Card style={{ height: "50%", marginTop: 5 }}>
              <CardContent dividers>
                <Formik
                  enableReinitialize
                  initialValues={intialUserFormData}
                  onSubmit={(values) => saveProjectUsers(values)}
                  innerRef={formRefUsers}
                >
                  {({
                    values,
                    setFieldValue,
                    errors,
                    touched,
                    handleChange,
                    isSubmitting,
                  }) => (
                    <>
                      <AppBar className={"appBar g-btn-secondary"}>
                        <Toolbar
                          style={{ minHeight: 40, padding: "0px 8px" }}
                        >
                          {getDialogBoxHeader("Team Members")}
                        </Toolbar>
                      </AppBar>
                      <Form disabled={true}>
                        <Box>
                          <AddUsersAndRole
                            values={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            users={props.users.filter(
                              (e) =>
                                e.emailAddress !== "it.support@taeurope.com"
                            )}
                          />
                        </Box>
                      </Form>
                    </>
                  )}
                </Formik>
              </CardContent>
              <CardActions style={{ marginTop: "auto" }} className="w-100">
                <CustomButton
                  id="create-project-button"
                  buttonText="_save"
                  variant="button"
                  style={{ marginLeft: "auto", width: "10%", height: 40 }}
                  tooltip={{
                    title: "click to create a project",
                  }}
                  handleClick={(e) => formRefUsers.current.handleSubmit()}
                />
              </CardActions>
            </Card>
          </>
        )}
      </div>
      {markDelete && (
        <>
          <DeleteConfirmationAlert
            open={markDelete}
            onClose={() => setMarkDelete(false)}
            warningMessage={getWarningText()}
            onConfirmation={() =>
              dispatch(
                deleteProject({
                  projectIdent: scope.projectIdent,
                })
              )
            }
          />
        </>
      )}
    </>
  );
};

ProjectDashboardContainer.propTypes = {
  data: PropTypes.object,
  serviceLine: PropTypes.string,
  users: PropTypes.array,
  updateProject: PropTypes.func,
  updateProjectUsers: PropTypes.func,
};

export default ProjectDashboardContainer;
