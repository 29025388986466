import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { ListItem, Box } from "@mui/material";
import { getBackground, getBorderColor, getVariantText } from "../../../../../../utils/helper";
import AreaInput from "../../../input-types/AreaInput";
import QuelleInput from "../../../input-types/QuelleInput";
import TextEditorInput from "../../../input-types/TextEditorInput";
import TextInput from "../../../input-types/TextInput";
import AddressInput from "../../../input-types/AddressInput";
import DateInput, { YearDateInput } from "../../../input-types/DateInput";
import { useDispatch, useSelector } from "react-redux";
import { setUpdatedRows } from "./buildingProfileTableSlice";
import NumberInput from "../../../input-types/NumberInput";

const RenderNodeValues = ({ item, onUpdate }) => {
  const [inputValues, setInputValues] = useState(item.nodeValue);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.buildingProfileTable);


  const onUpdateCheck = (item, nodeValues) => {
    nodeValues.forEach((node, i) => {
      if(node.updated){
        let tempObj = {
          nodeIdent: item.nodeIdent,
          subAssetIdent: node.subAsset.subAssetIdent,
          labelTranslation: item.labelTranslation,
          updated: node.updated,
          item: item,
        };
        dispatch(setUpdatedRows(tempObj));
        //dispatch(selectedOptionStructure(tempObj));
      }
    })
  };

  const onChange = (value, node, isUpdated, subAsset, item) => {
    if (isUpdated) {
        let temp = inputValues.map((m) => {
          if (m.subAsset.subAssetIdent === subAsset.subAssetIdent) {
            return {
                ...m,
                value: value,
                updated: isUpdated, 
            };
          } else {
            return m;
          }
        });
        setInputValues(temp);
        onUpdate(item, temp);
        onUpdateCheck(item,temp);
      }
  };

  const onQuelleChange = (value, node, isUpdated, subAsset, item) => {
    if (isUpdated) {
        let temp = item.nodeValue.map((m) => {
          if (m.subAsset.subAssetIdent === subAsset.subAssetIdent) {
            return {
                ...m,
                quelle: value,
                updated: isUpdated,
            };
          } else {
            return m;
          }
        });
        onUpdate(item, temp);
  };
}
  const renderNodeValues = (item, node, index) => {
    let subAsset = node.subAsset;
    let found = state.updatedRows?.some(
      (f) =>
        f.nodeIdent === item.nodeIdent &&
        f.subAssetIdent === subAsset.subAssetIdent
    );
    return (
      <ListItem
        className={"d-flex flex-column justify-contents-start w-100 p-0"}
        id={`id-${item.nodeIdent}-${subAsset.subAssetIdent}`}
        key={`key-${item.nodeIdent}-${subAsset.subAssetIdent}`}
        style={{borderLeft: getBorderColor(found),
        backgroundColor: getBackground(found)
        }}
      >
        <Box className={"d-flex align-items-center flex-wrap w-100"}>
            <div style={{ width: "25%" }} className="d-flex">
              <div className="d-flex  justify-content-start m-1">
                {getVariantText(subAsset.name, "caption", {
                  color: "#254a9a",
                  textTransform: "uppercase",
                  wordBreak: "break-all",
                })}
              </div>
            </div>

          <div
            className="p-1"
            style={{ width: "75%"}}
          >
            <div className="p-1 w-100">
              {getInputFields(item, node, subAsset)}
            </div>
          </div>
        </Box>
      </ListItem>
    );
  };

  const getInputFields = (item, node, subAsset) => {
    const id = `id-${item.nodeName}-${subAsset.subAssetIdent}`;
    const key = `key-${item.nodeName}-${subAsset.subAssetIdent}`;
    const disabled = item.disabled || false;

    if (item.nodeType.includes("-number")) {
      return (
        <Box className="d-flex w-100 p-1">
          <Box style={{ width: "50%" }} className="m-1">
            <NumberInput
              id={id}
              key={key}
              disabled={disabled}
              node={node}
              handleChange={(value, node , isUpdated) =>
                onChange(value, node, isUpdated, subAsset, item)
              }
            />
          </Box>
          <Box style={{ width: "50%" }} className="m-1">
            <QuelleInput
              id={id}
              key={key}
              disabled={disabled}
              node={node}
              handleQuelleChange={(value, node, isUpdated) =>
                onQuelleChange(value, node,isUpdated, subAsset, item)
              }
            />
          </Box>
        </Box>
      );
    }
    if (item.nodeType.includes("-area")) {
      return (
        <Box className="d-flex w-100 p-1">
          <Box style={{ width: "50%" }} className="m-1">
            <AreaInput
              id={id}
              key={key}
              disabled={disabled}
              node={node}
              handleChange={(value, node , isUpdated) =>
                onChange(value, node, isUpdated, subAsset, item)
              }
            />
          </Box>
          <Box style={{ width: "50%" }} className="m-1">
            <QuelleInput
              id={id}
              key={key}
              disabled={disabled}
              node={node}
              handleQuelleChange={(value, node, isUpdated) =>
                onQuelleChange(value, node,isUpdated, subAsset, item)
              }
            />
          </Box>
        </Box>
      );
    }

    switch (item.nodeType) {
      case "textarea":
        return (
          <Box className="d-flex flex-column w-100 p-1">
            <Box style={{ width: "100%" }} className="m-1">
              <TextEditorInput
                id={id}
                key={key}
                disabled={disabled}
                node={node}
                handleChange={(value, node , isUpdated) =>
                    onChange(value, node, isUpdated, subAsset, item)
                  }
              />
            </Box>
            <Box style={{ width: "100%" }} className="m-1">
              <QuelleInput
                id={id}
                key={key}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) =>
                    onQuelleChange(value, node,isUpdated, subAsset, item)
                  }
              />
            </Box>
          </Box>
        );

      case "number":
        return (
          <Box className="d-flex w-100 p-1">
            <Box style={{ width: "50%" }} className="m-1">
              <NumberInput
                id={id}
                key={key}
                disabled={disabled}
                node={node}
                handleChange={(value, node , isUpdated) =>
                    onChange(value, node, isUpdated, subAsset, item)
                  }
              />
            </Box>
            <Box style={{ width: "50%" }} className="m-1">
              <QuelleInput
                id={id}
                key={key}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) =>
                    onQuelleChange(value, node,isUpdated, subAsset, item)
                  }
              />
            </Box>
          </Box>
        );

      case "area":
        return (
          <Box className="d-flex w-100 p-1">
            <Box style={{ width: "50%" }} className="m-1">
              <AreaInput
                id={id}
                key={key}
                disabled={disabled}
                node={node}
                handleChange={(value, node , isUpdated) =>
                    onChange(value, node, isUpdated, subAsset, item)
                  }
              />
            </Box>
            <Box style={{ width: "50%" }} className="m-1">
              <QuelleInput
                id={id}
                key={key}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) =>
                    onQuelleChange(value, node,isUpdated, subAsset, item)
                  }
              />
            </Box>
          </Box>
        );

      case "date":
      case "date-future":
        return (
          <Box className="d-flex w-100 p-1">
            <Box style={{ width: "50%" }} className="m-1">
              <DateInput
                id={id}
                key={key}
                disabled={disabled}
                node={node}
                disableFuture={item.type === "date"}
                handleChange={(value, node , isUpdated) =>
                    onChange(value, node, isUpdated, subAsset, item)
                  }
              />
            </Box>
            <Box style={{ width: "50%" }} className="m-1">
              <QuelleInput
                id={id}
                key={key}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) =>
                    onQuelleChange(value, node,isUpdated, subAsset, item)
                  }
              />
            </Box>
          </Box>
        );

      case "date-year":
        return (
          <Box className="d-flex w-100 p-1">
            <Box style={{ width: "50%" }} className="m-1">
              <YearDateInput
                id={id}
                key={key}
                disabled={disabled}
                node={node}
                handleChange={(value, node , isUpdated) =>
                    onChange(value, node, isUpdated, subAsset, item)
                  }
              />
            </Box>
            <Box style={{ width: "50%" }} className="m-1">
              <QuelleInput
                id={id}
                key={key}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) =>
                    onQuelleChange(value, node,isUpdated, subAsset, item)
                  }
              />
            </Box>
          </Box>
        );

      case "address":
        return (
          <Box className="d-flex  flex-column w-100">
            <Box style={{ width: "100%" }} className="m-1">
              <AddressInput
                id={id}
                key={key}
                disabled={disabled}
                node={node}
                mapConfig={{
                  nodeIdent: item.nodeIdent,
                  ident: subAsset.subAssetIdent,
                  value: node.value,
                }}
                handleChange={(value, node , isUpdated) =>
                    onChange(value, node, isUpdated, subAsset, item)
                  }
              />
            </Box>
            <Box style={{ width: "100%" }} className="m-1">
              <QuelleInput
                id={id}
                key={key}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) =>
                    onQuelleChange(value, node,isUpdated, subAsset, item)
                  }
              />
            </Box>
          </Box>
        );

      default:
        return (
          <Box className="d-flex w-100 ">
            <Box style={{ width: "50%" }} className="m-1">
              <TextInput
                id={id}
                key={key}
                disabled={disabled}
                node={node}
                handleChange={(value, node , isUpdated) =>
                    onChange(value, node, isUpdated, subAsset, item)
                  }
              />
            </Box>
            <Box style={{ width: "50%" }} className="m-1">
              <QuelleInput
                id={id}
                key={key}
                disabled={disabled}
                node={node}
                handleQuelleChange={(value, node, isUpdated) =>
                    onQuelleChange(value, node,isUpdated, subAsset, item)
                  }
              />
            </Box>
          </Box>
        );
    }
  };

  return (
    <>
      {!!inputValues &&
        inputValues.length > 0 &&
        inputValues.map((node, index) => {
          return renderNodeValues(item, node, index);
        })}
    </>
  );
};

RenderNodeValues.propTypes = {};

export default RenderNodeValues;
