import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import {
  getDialogBoxHeader,
  getIntlTranslation,
  getLabel,
} from "../../../utils/helper";
import CustomButton from "../../../components/buttons/CustomButton";
import useGenerateReportHook from "../hooks/useGenerateReportHook";
import {
  draftVersion,
  englishLanguage,
  germanLanguage,
  finalVersion,
} from "../../../utils/defaultValues";
import {
  Alert,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import useAssetHooks from "../hooks/useAssetHooks";

const reportVersions = [draftVersion, finalVersion];
const reportLanguageOptions = [germanLanguage, englishLanguage];
const defaultState = {
  selectedAsset: {
    name: "",
    ident: "",
  },
  type: {
    value: "",
    label: "",
    key: "",
    disabled: false,
  },
  lang: { label: "Deutsch", value: "de" },
  version: {
    value: "Draft",
    label: "Draft",
  },
};

const test = {}

if(!!test){
  console.log("test me")
}
function ReportGenerationDialog(props) {
  const reportState = useSelector((state) => state.report);
  const [handleReportSubmit, loading] = useGenerateReportHook();
  const [openDialog, setOpenDialog] = useState(false);
  const [report, setReport] = useState(defaultState);
  const { fetchAsset, asset } = useAssetHooks();


  useEffect(() => {
    if (!!props) {
      const temp = {
        selectedAsset: !!props.selectedData ? props.selectedData : props.filterDataList[0],
        type: props.reportOptions[0],
        lang: { label: "Deutsch", value: "de", checked: true },
        version: props.reportOptions[0]?.key === "rf"
        ? {...finalVersion, checked:true} 
        : {...draftVersion, checked:true} 
      }
     console.log('userSelection', reportState,reportState.userSelection);
      if('type' in reportState.userSelection && Object.keys(reportState.userSelection.type).length >  0){
        temp['type'] = reportState.userSelection.type;
        
      }
      if('lang' in reportState.userSelection && Object.keys(reportState.userSelection.lang).length >  0){
        temp['lang'] = reportState.userSelection.lang;
        
      }
      if('version' in reportState.userSelection && Object.keys(reportState.userSelection.version).length >  0){
        temp['version'] = reportState.userSelection.version;
        
      }

      setReport(
        temp
      );
    }
  }, [props, reportState.userSelection]);



  const getReportVersionOption = () => {
    if (report.type?.key === "rf") {
      return [finalVersion];
    }
    return reportVersions;
  };

  const onSelectionChange = (checked, value, type) => {
    if (checked) {
      setReport((prev) => ({ ...prev, [type]: value }));
    }
  };

  return (
    <>
      <div className="d-flex align-items-center w-100 m-0 p-0 h-100">
        <Tooltip title={getIntlTranslation("tooltip.open_reports_dialog")}>
          <IconButton
            size="small"
            aria-label="generate report"
            style={{ height: 22 }}
            className={`d-flex g-btn-elevated align-items-center w-100 m-1 justify-content-around p-0 rounded-3 h-100 ${props.className}`}
            onClick={() => {
              setOpenDialog(true);
              if (!!props.selectedData) {
                setReport((prev) => ({
                  ...prev,
                  selectedAsset: props.selectedData,
                  type: props.reportOptions[0],
                  version:
                    props.reportOptions[0]?.key === "rf"
                      ? finalVersion
                      : draftVersion,
                }));
              }
              if (props.selectedData?.isParent) {
                fetchAsset(props.selectedData.parentIdent);
              }
            }}
            id={props.id}
          >
            <p
              style={{
                fontSize: 11,
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
              className="m-0"
            >
              {getIntlTranslation("_generate-report")}
            </p>
            <AssignmentIcon
              style={{
                color: "#ffffff",
                fontSize: 20,
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
      {openDialog && (
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={openDialog}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setOpenDialog(false);
              setReport(defaultState);
            }
          }}
        >
          <AppBar className={"appBar"}>
            <Toolbar
              className={"dialog-toolbar"}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {getDialogBoxHeader("_generate-report")}
              <IconButton
                edge="start"
                color="inherit"
                id="create_project_close"
                onClick={(e) => {
                  setOpenDialog(false);
                  setReport(defaultState);
                }}
                aria-label="close"
                style={{ marginLeft: "auto" }}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Divider style={{ height: "2px" }} />
          {loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          <DialogContent className="container custom-scrollBar p-2" dividers>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <div className="w-100 m-1">
                <Autocomplete
                  id="reportGeneration"
                  size="small"
                  autoHighlight={true}
                  style={{ padding: 5 }}
                  value={report.selectedAsset}
                  options={props.filterDataList}
                  onChange={(event, newValue) => {
                    if (!!newValue && !!newValue.ident) {
                      setReport({ ...report, selectedAsset: newValue });
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) =>
                    value.ident === option.ident
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      multiline
                      variant="outlined"
                      size="small"
                      label={getLabel("_select asset")}
                    />
                  )}
                />
              </div>
              <Box className="d-flex flex-wrap flex-grow-1 align-items-center w-100 p-1">
                <Box
                  className="d-flex align-items-center p-1"
                  style={{ width: "20%" }}
                >
                  <Typography variant="subtitle2">
                    {getLabel("_report-type")}
                  </Typography>
                </Box>
                <List
                  className="d-flex flex-row flex-wrap flex-grow-1 align-items-center p-0"
                  style={{ width: "80%" }}
                >
                  {props.reportOptions.map((option,index) => (
                    <ListItem
                      className="d-flex align-items-center p-0 m-0"
                      style={{ width: "32%" }}
                      key={`type-${index}`}
                    >
                      <Checkbox
                        checked={report.type?.value === option.value}
                        onChange={(e) =>
                          onSelectionChange(e.target.checked, option, "type")
                        }
                        inputProps={{ "aria-label": "report type" }}
                      />
                      <ListItemText
                        primary={
                          <Typography variant="subtitle2">
                            {option.label}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box className="d-flex flex-wrap flex-grow-1 align-items-center w-100 p-1">
                <Box
                  className="d-flex align-items-center p-1"
                  style={{ width: "20%" }}
                >
                  <Typography variant="subtitle2">
                    {getLabel("_report-language")}
                  </Typography>
                </Box>
                <List
                  className="d-flex flex-row flex-wrap flex-grow-1 align-items-center p-0"
                  style={{ width: "80%" }}
                >
                  {reportLanguageOptions.map((option, index) => (
                    <ListItem
                      className="d-flex align-items-center p-0 m-0"
                      style={{ width: "32%" }}
                      key={`lang-${index}`}
                    >
                      <Checkbox
                        checked={report.lang.value === option.value}
                        onChange={(e) =>
                          onSelectionChange(e.target.checked, option, "lang")
                        }
                        inputProps={{ "aria-label": "report language" }}
                      />
                      <ListItemText
                        primary={
                          <Typography variant="subtitle2">
                            {option.label}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box className="d-flex flex-wrap flex-grow-1 align-items-center w-100 p-1">
                <Box
                  className="d-flex align-items-center p-1"
                  style={{ width: "20%" }}
                >
                  <Typography variant="subtitle2">
                    {getLabel("_report-version")}
                  </Typography>
                </Box>
                <List
                  className="d-flex flex-row flex-wrap flex-grow-1 align-items-center p-0"
                  style={{ width: "80%" }}
                >
                  {getReportVersionOption().map((option, index) => (
                    <ListItem
                      className="d-flex align-items-center p-0 m-0"
                      style={{ width: "32%" }}
                      key={`verion-${index}`}
                    >
                      <Checkbox
                        checked={report.version.value === option.value}
                        onChange={(e) =>
                          onSelectionChange(e.target.checked, option, "version")
                        }
                        inputProps={{ "aria-label": "report version" }}
                      />
                      <ListItemText
                        primary={
                          <Typography variant="subtitle2">
                            {option.label}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions className="w-100 d-flex align-items-center">
            <Box style={{ width: "85%" }}>
              {!!asset && asset?.capexGenerationRequired && (
                <Alert severity="warning" variant="filled">
                  {getLabel("_Capex-alert")}
                </Alert>
              )}
            </Box>
            <CustomButton
              id="generate_capex_01"
              buttonText={"_Generate"}
              variant="button"
              style={{
                margin: 5,
                backgroundColor: "#244a9a",
                color: "#ffffff",
              }}
              tooltip={{
                title: getIntlTranslation("tooltip.click-to-generate"),
              }}
              handleClick={() => handleReportSubmit(report)}
            />
          </DialogActions>
          {loading && (
            <Backdrop style={{ zIndex: "1800", color: "#fff" }} open={loading}>
              <div className="preloader">
                <div></div>
              </div>
            </Backdrop>
          )}
        </Dialog>
      )}
    </>
  );
}

ReportGenerationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ReportGenerationDialog;
