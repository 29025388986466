import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import { Box, Divider, Grow } from "@mui/material";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import { styled } from "@mui/material/styles";
import { Drawer as MuiDrawer } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CreateDefect from "../../defects/create/CreateDefect";
import { setOptionalSubCategories } from "../keyFindingSlice";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { getIntlTranslation } from "../../../../../utils/helper";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  position: "relative",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: "hidden",
  zIndex: 1030,
  marginBottom: 2,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  position: "relative",
  overflow: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(5)} + 1px)`,
  },
  zIndex: 1030,
  marginBottom: 2,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  backgroundColor: "#7facd5",
  height: 80,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SubCategoriesPanel = (props) => {
  const { category } = props;
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.tddKeyFinding);
  const [openDefect, setOpenDefect] = useState({
    open: false,
    subCategory: {},
  });
  const [subCategories, setSubCategories] = useState([]);
  const scope = useSelector((state) => state.container.scope);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!!category) {
      if (state.optionalSubCategories.length > 0) {
        setSubCategories(
          category.children.map((val) => {
            let temp = { ...val, parentKey: category.key };
            let found = state.optionalSubCategories.find(
              (m) =>
                m.title.find((f) => f.language === "de").value ===
                val.title.find((f) => f.language === "de").value
            );
            if (!!found) {
              temp["checked"] = found.checked;
            }
            return temp;
          })
        );
      } else {
        setSubCategories(
          category?.children.map((m) => ({ ...m, parentKey: category.key }))
        );
      }
    }
  }, [category, state.optionalSubCategories]);

  const prepareCheckedOptions = (checked, val) => {
    let temp = [];
    if (state.optionalSubCategories.length > 0) {
      temp = state.optionalSubCategories.map((m) => {
        let tempObj = m;
        if (
          m.title.find((f) => f.language === "de").value ===
          val.title.find((f) => f.language === "de").value
        ) {
          tempObj = { ...m, checked: checked };
        }
        return tempObj;
      });
    }

    if (
      !temp.length ||
      !temp.find(
        (f) =>
          f.title.find((f) => f.language === "de").value ===
          val.title.find((f) => f.language === "de").value
      )
    ) {
      temp.push({ ...val, checked: checked });
    }
    return temp;
  };

  const setSelectedSubCategory = (e, val) => {
    let temp = prepareCheckedOptions(e.target.checked, val);
    dispatch(setOptionalSubCategories(temp));
  };

  const selectAll = (e) => {
    let optionalSubCategories = [...state.optionalSubCategories];
    let temp = subCategories.map((val) => {
      if (optionalSubCategories.length > 0) {
        let found = optionalSubCategories.findIndex(
          (m) =>
            m.title.find((f) => f.language === "de").value ===
            val.title.find((f) => f.language === "de").value
        );
        if (found >= 0) {
          optionalSubCategories.splice(found, 1);
        }
        return { ...val, checked: e.target.checked };
      } else {
        return { ...val, checked: e.target.checked };
      }
    });
    dispatch(setOptionalSubCategories([...optionalSubCategories, ...temp]));
  };

  const numberOfChecked = () => {
    return subCategories.filter((f) => f.checked).length;
  };

  const onCreateDefect = (val) => {
    setOpenDefect({
      open: true,
      subCategory: val,
    });
  };

  const createSubCategoriesLink = () => {
    const nodeLinks = [];
    if (subCategories.length > 0) {
      subCategories.forEach((subCategory) => {
        let subCatTitle = subCategory.title.find(
          (e) => e.language === scope.projectLanguageCode
        );
        nodeLinks.push(
          <ListItem key={subCategory.ident} style={{ whiteSpace: "pre-wrap" }}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={
                  subCategory.checked === undefined
                    ? false
                    : subCategory.checked
                }
                tabIndex={-1}
                onChange={(e) => setSelectedSubCategory(e, subCategory)}
                disableRipple
                inputProps={{ "aria-labelledby": subCategory.key }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Box className={"d-flex align-items-center w-100"}>
                  <Typography variant="body2" style={{ width: "80%" }}>
                    {subCatTitle.title}
                  </Typography>
                  {open && (
                    <CustomIconButton
                      id="defect_create_button_1"
                      handleClick={(event) => onCreateDefect(subCategory)}
                      icon={
                        <PlaylistAddIcon
                          style={{
                            color: "#d08f8e",
                            borderRadius: 4,
                            width: 30,
                            height: 30,
                          }}
                        />
                      }
                      tooltip={{
                        title: getIntlTranslation("tooltip.create-Defect"),
                      }}
                    />
                  )}
                </Box>
              }
            />
          </ListItem>
        );
      });
    }
    return nodeLinks;
  };
  return (
    <>
      <Box className="d-flex flex-column">
        <DrawerHeader
          className="sub-header"
          style={{ minHeight: 50, height: 50, marginTop:1 }}
        >
          <IconButton
            style={{ padding: 4, marginLeft: "auto" }}
            onClick={(e) => handleDrawerOpen()}
          >
            {open ? (
              <NavigateBeforeIcon style={{ color: "#ffffff", fontSize: 30 }} />
            ) : (
              <NavigateNextIcon style={{ color: "#ffffff", fontSize: 30 }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Grow
          in={open}
          style={{ transformOrigin: "0 0 0" }}
          {...(open ? { timeout: 1000 } : { timeout: 1000 })}
        >
          <Drawer
            variant="permanent"
            open={open}
            style={{ height: "calc(100% - 40px)" }}
          >
            <List
              style={{ overflow: "auto", marginBottom: 2 }}
              className="custom-scrollBar"
            >
              {open && (
                <>
                  <ListItem
                    style={{
                      width: "80%",
                      whiteSpace: "pre-wrap",
                      paddingLeft: 0,
                    }}
                    disablePadding
                  >
                    <ListItemIcon style={{ height: 56 }}>
                      <Checkbox
                        onClick={(e) => selectAll(e)}
                        checked={
                          numberOfChecked() === subCategories.length &&
                          subCategories.length !== 0
                        }
                        indeterminate={
                          numberOfChecked() !== subCategories.length &&
                          numberOfChecked() !== 0
                        }
                        disabled={subCategories.length === 0}
                        inputProps={{
                          "aria-label": "all items selected",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={"select-all-01"}
                      primary={getIntlTranslation("_select all")}
                      secondary={`${numberOfChecked()}/${
                        subCategories.length
                      } selected`}
                    />
                  </ListItem>
                  <Divider />
                  {createSubCategoriesLink()}
                </>
              )}
            </List>
          </Drawer>
        </Grow>
      </Box>
      {openDefect.open && (
        <CreateDefect
          open={openDefect.open}
          subCategory={openDefect.subCategory}
          onClose={() =>
            setOpenDefect({
              open: false,
              subCategory: {},
            })
          }
          onUpdate={() => {
            props.onUpdate();
          }}
        />
      )}
    </>
  );
};

SubCategoriesPanel.propTypes = {
  category: PropTypes.object,
  onUpdate: PropTypes.func,
};

export default SubCategoriesPanel;
