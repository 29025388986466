import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { setUpdatedRows } from '../tddBuildingProfileSlice';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AddressInput from '../../../common/input-types/AddressInput';
import AreaInput from '../../../common/input-types/AreaInput';
import DateInput, { YearDateInput } from '../../../common/input-types/DateInput';
import NumberInput from '../../../common/input-types/NumberInput';
import QuelleInput from '../../../common/input-types/QuelleInput';
import TextEditorInput from '../../../common/input-types/TextEditorInput';
import TextInput from '../../../common/input-types/TextInput';
import { useBuildingProfileContextHook } from './BuildingProfileContext';
import TextInputWithCheckBox from '../../../common/input-types/TextInputWithCheckBox';

const RenderInputType = ({item}) => {
    const {properties, setProperties} = useBuildingProfileContextHook(); 
    const dispatch = useDispatch();
    const openAsset = useSelector((state) => state.openAsset);

    const onUpdateCheck = (item) => {
      dispatch(setUpdatedRows(item));
      //dispatch(selectedOptionStructure(tempObj));
    }

    const onChange = (value, node, isUpdated, item) => {
        if (isUpdated) {
          setProperties(prevProperties => {
            const newProperties = new Map(prevProperties);
            newProperties.set(item.nodeName, {...node, value:value, updated: true});
            return newProperties;
          });
          }
          onUpdateCheck({...item, updated:true});
      };
    
      const onQuelleChange = (value, node, isUpdated, item) => {
        if (isUpdated) {
          setProperties(prevProperties => {
            const newProperties = new Map(prevProperties);
            newProperties.set(item.nodeName, {...node, quelle:value, updated: true});
            return newProperties;
          });
          onUpdateCheck({...item, updated:true});
      };
    }

    const getInputFields = (item) => {
        const id = `id-${item.nodeName}-${openAsset?.subAssetIdent}`;
        const key = `key-${item.nodeName}-${openAsset?.subAssetIdent}`;
        const disabled = item.disabled || false;
        const node = properties.get(item.nodeName);
    
        if (item.nodeType.includes("-number")) {
          return (
            <Box className="d-flex w-100 p-1">
              <Box style={{ width: "50%" }} className="m-1">
                <NumberInput
                  id={id}
                  key={key}
                  disabled={disabled}
                  node={{...node}}
                  handleChange={(value, node , isUpdated) =>
                    onChange(value, node, isUpdated, item)
                  }
                />
              </Box>
              <Box style={{ width: "50%" }} className="m-1">
                <QuelleInput
                  id={id}
                  key={key}
                  disabled={disabled}
                   node={{...node}}
                  handleQuelleChange={(value, node, isUpdated) =>
                   onQuelleChange(value, node, isUpdated,item)
                  }
                />
              </Box>
            </Box>
          );
        }
        if (item.nodeType.includes("-area")) {
          return (
            <Box className="d-flex w-100 p-1">
              <Box style={{ width: "50%" }} className="m-1">
                <AreaInput
                  id={id}
                  key={key}
                  disabled={disabled}
                   node={{...node}}
                  handleChange={(value, node , isUpdated) =>
                    onChange(value, node, isUpdated, item)
                  }
                />
              </Box>
              <Box style={{ width: "50%" }} className="m-1">
                <QuelleInput
                  id={id}
                  key={key}
                  disabled={disabled}
                   node={{...node}}
                  handleQuelleChange={(value, node, isUpdated) =>
                   onQuelleChange(value, node, isUpdated,item)
                  }
                />
              </Box>
            </Box>
          );
        }
    
        switch (item.nodeType) {
          case "textarea":
            return (
              <Box className="d-flex flex-column w-100 p-1">
                <Box style={{ width: "100%" }} className="m-1">
                  <TextEditorInput
                    id={id}
                    key={key}
                    disabled={disabled}
                     node={{...node}}
                    handleChange={(value, node , isUpdated) =>
                        onChange(value, node, isUpdated, item)
                      }
                  />
                </Box>
                <Box style={{ width: "100%" }} className="m-1">
                  <QuelleInput
                    id={id}
                    key={key}
                    disabled={disabled}
                     node={{...node}}
                    handleQuelleChange={(value, node, isUpdated) =>
                     onQuelleChange(value, node, isUpdated,item)
                      }
                  />
                </Box>
              </Box>
            );
    
          case "number":
            return (
              <Box className="d-flex w-100 p-1">
                <Box style={{ width: "50%" }} className="m-1">
                  <NumberInput
                    id={id}
                    key={key}
                    disabled={disabled}
                     node={{...node}}
                    handleChange={(value, node , isUpdated) =>
                        onChange(value, node, isUpdated, item)
                      }
                  />
                </Box>
                <Box style={{ width: "50%" }} className="m-1">
                  <QuelleInput
                    id={id}
                    key={key}
                    disabled={disabled}
                     node={{...node}}
                    handleQuelleChange={(value, node, isUpdated) =>
                     onQuelleChange(value, node, isUpdated,item)
                      }
                  />
                </Box>
              </Box>
            );
    
          case "area":
            return (
              <Box className="d-flex w-100 p-1">
                <Box style={{ width: "50%" }} className="m-1">
                  <AreaInput
                    id={id}
                    key={key}
                    disabled={disabled}
                     node={{...node}}
                    handleChange={(value, node , isUpdated) =>
                        onChange(value, node, isUpdated, item)
                      }
                  />
                </Box>
                <Box style={{ width: "50%" }} className="m-1">
                  <QuelleInput
                    id={id}
                    key={key}
                    disabled={disabled}
                     node={{...node}}
                    handleQuelleChange={(value, node, isUpdated) =>
                     onQuelleChange(value, node, isUpdated,item)
                      }
                  />
                </Box>
              </Box>
            );
    
          case "date":
          case "date-future":
            return (
              <Box className="d-flex w-100 p-1">
                <Box style={{ width: "50%" }} className="m-1">
                  <DateInput
                    id={id}
                    key={key}
                    disabled={disabled}
                     node={{...node}}
                    disableFuture={item.type === "date"}
                    handleChange={(value, node , isUpdated) =>
                        onChange(value, node, isUpdated, item)
                      }
                  />
                </Box>
                <Box style={{ width: "50%" }} className="m-1">
                  <QuelleInput
                    id={id}
                    key={key}
                    disabled={disabled}
                     node={{...node}}
                    handleQuelleChange={(value, node, isUpdated) =>
                     onQuelleChange(value, node, isUpdated,item)
                      }
                  />
                </Box>
              </Box>
            );
    
          case "date-year":
            return (
              <Box className="d-flex w-100 p-1">
                <Box style={{ width: "50%" }} className="m-1">
                  <YearDateInput
                    id={id}
                    key={key}
                    disabled={disabled}
                     node={{...node}}
                    handleChange={(value, node , isUpdated) =>
                        onChange(value, node, isUpdated, item)
                      }
                  />
                </Box>
                <Box style={{ width: "50%" }} className="m-1">
                  <QuelleInput
                    id={id}
                    key={key}
                    disabled={disabled}
                     node={{...node}}
                    handleQuelleChange={(value, node, isUpdated) =>
                     onQuelleChange(value, node, isUpdated,item)
                      }
                  />
                </Box>
              </Box>
            );
    
          case "address":
            return (
              <Box className="d-flex  flex-column w-100">
                <Box style={{ width: "100%" }} className="m-1">
                  <AddressInput
                    id={id}
                    key={key}
                    disabled={disabled}
                     node={{...node}}
                    mapConfig={{
                      nodeIdent: item.nodeIdent,
                      ident: openAsset?.subAssetIdent,
                      value: node.value,
                    }}
                    handleChange={(value, node , isUpdated) =>
                        onChange(value, node, isUpdated, item)
                      }
                  />
                </Box>
                <Box style={{ width: "100%" }} className="m-1">
                  <QuelleInput
                    id={id}
                    key={key}
                    disabled={disabled}
                     node={{...node}}
                    handleQuelleChange={(value, node, isUpdated) =>
                     onQuelleChange(value, node, isUpdated,item)
                      }
                  />
                </Box>
              </Box>
            );
            case "boolean":
              return (
                <Box className="d-flex w-100 ">
                <Box style={{ width: "50%" }} className="m-1">
                  <TextInputWithCheckBox
                    id={id}
                    key={key}
                    disabled={disabled}
                     node={{...node}}
                    handleChange={(value, node , isUpdated) =>
                        onChange(value, node, isUpdated, item)
                      }
                  />
                </Box>
                <Box style={{ width: "50%" }} className="m-1">
                  <QuelleInput
                    id={id}
                    key={key}
                    disabled={disabled}
                     node={{...node}}
                    handleQuelleChange={(value, node, isUpdated) =>
                      onQuelleChange(value, node, isUpdated,item)
                      }
                  />
                </Box>
              </Box>
              );
          default:
            return (
              <Box className="d-flex w-100 ">
                <Box style={{ width: "50%" }} className="m-1">
                  <TextInput
                    id={id}
                    key={key}
                    disabled={disabled}
                     node={{...node}}
                    handleChange={(value, node , isUpdated) =>
                        onChange(value, node, isUpdated, item)
                      }
                  />
                </Box>
                <Box style={{ width: "50%" }} className="m-1">
                  <QuelleInput
                    id={id}
                    key={key}
                    disabled={disabled}
                     node={{...node}}
                    handleQuelleChange={(value, node, isUpdated) =>
                      onQuelleChange(value, node, isUpdated,item)
                      }
                  />
                </Box>
              </Box>
            );
        }
      };
  return (
   <>{getInputFields(item)}</>
  )
}

RenderInputType.propTypes = {}

export default RenderInputType