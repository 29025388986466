import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  IconButton,
  Divider,
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Typography,
  Alert,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  FormHelperText,
  AppBar,
  Toolbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import InfoIcon from "@mui/icons-material/Info";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon for the close button

import { tooltipClasses } from "@mui/material/Tooltip";

import {
  riskOptions,
  priorityOptions,
  defectTypes,
} from "../../../../common/dropdowns";
import {
  getLanguageBasedOption,
  setDefaultValues,
  setDefaultRiskTypeValues,
  getDefaultSelectionRecommendations,
} from "./defectHelper";
import { fetchCostElementList } from "../../api/costElementApi";
import { getLabel, getIntlTranslation, getTranslationVariantText, getDialogBoxHeader} from "../../../../utils/helper";
import {riskTypeOptions} from "../../../../utils/constants";

const ImageTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const radioButton = {
  "&.Mui-checked": {
    color: pink[600],
  },
};

function DefectSection(props) {
  const { values, touched, errors, setFieldValue, node } = props;
  const dispatch = useDispatch();
  const defectTypeOption = defectTypes;
  const riskTypeOption = riskOptions;
  const scope = useSelector((state) => state.container.scope);
  const priorityTypeOptions = !values.costDefect
    ? priorityOptions.filter((f) => f.nonCostDefect)
    : priorityOptions;

  const [apiCallCondition, setApiCallCondition] = useState({
    projectIdent: "",
    ident: "",
    elementName: "",
    defectType: "",
  });

  const [openInfoAlert, setOpenInfoAlert] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (
      !!values.defectType &&
      !!values.elementName &&
      !!values.elementName.parentIdent
    ) {
      setApiCallCondition((prev) => {
        if (
          prev.defectType === values.defectType &&
          prev.elementName === values.elementName.value
        ) {
          return prev;
        } else {
          return {
            projectIdent: scope.projectIdent,
            ident: values.elementName.parentIdent,
            elementName: values.elementName.value,
            defectType: values.defectType,
          };
        }
      });
    }
  }, [scope.projectIdent, values.defectType, values.elementName]);

  useEffect(() => {
    if (!!apiCallCondition.defectType && !!apiCallCondition.elementName) {
      dispatch(
        fetchCostElementList({
          projectIdent: apiCallCondition.projectIdent,
          ident: apiCallCondition.ident,
          name: apiCallCondition.elementName,
          defectType: apiCallCondition.defectType,
        })
      );
    }
  }, [apiCallCondition, dispatch]);

  const getDefaultSelectionAndInfos = () => {
    const data = getDefaultSelectionRecommendations(values.defectType);
    const defectType = defectTypes.find((f) => f.value === data.defectType);
    let risk = [];
    let priority = [];
    if (!!data.risk) {
      risk = data.risk.map((m) => {
        let found = riskOptions.find((f) => f.value === m.type);
        return (
          <ListItem alignItems="flex-start" style={{ padding: 0 }}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="caption"
                    color="text.primary"
                  >
                    {found?.title[scope.projectLanguageCode]}
                  </Typography>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="caption"
                    color="text.primary"
                  >
                    {`: ${m.info}`}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        );
      });
    }
    if (!!data.priority) {
      priority = data.priority.map((m) => {
        let found = priorityOptions.find((f) => f.value === m.type);
        return (
          <ListItem alignItems="flex-start" style={{ padding: 0 }}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="caption"
                    color="text.primary"
                  >
                    {found?.title[scope.projectLanguageCode]}
                  </Typography>
                  {m.info}
                </React.Fragment>
              }
            />
          </ListItem>
        );
      });
    }

    return (
      <Alert
        variant="filled"
        severity="info"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpenInfoAlert((prev) => !prev);
            }}
          >
            {openInfoAlert ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
      >
        <div>
          <strong>
            {getIntlTranslation("_Our-Recommendation-For")}: {"  "}
            {defectType?.title[scope.projectLanguageCode]}
          </strong>
          <Collapse in={openInfoAlert}>
            <Box>
              {risk.length > 0 && (
                <>
                  <Typography component="span" variant="body2">
                    {getLabel("_risk")}
                  </Typography>

                  <List dense={"true"} style={{ padding: 0 }}>
                    {risk}
                  </List>
                </>
              )}
              {priority.length > 0 && (
                <>
                  <Typography component="span" variant="body2">
                    {getLabel("_Priority")}
                  </Typography>

                  <List>{priority}</List>
                </>
              )}
            </Box>
          </Collapse>
        </div>
      </Alert>
    );
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setFieldValue("inRedFlag", isChecked);

    // Check if risk type is low
    if (isChecked && values.riskType === riskTypeOptions.LOW) {
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Box style={{ width: "60%" }} className={"d-flex"}>
      <Divider
        orientation="vertical"
        flexItem
        style={{ margin: "0 5px", backgroundColor: "#d08f8e" }}
      />
      <Box
        style={{ width: "100%", margin: 10 }}
        className="custom-grid-container justify-content-start"
      >
        <Grid item md={12} xs={12} style={{ marginTop: 5 }}>
          <Box className={"d-flex flex-column"}>
            <FormLabel
              component="div"
              style={{
                width: "50%",
                marginTop: 10,
                color:
                  touched.defectType && errors.defectType
                    ? "#f44336"
                    : "#00000099",
              }}
              error={touched.defectType && errors.defectType}
            >
              {getLabel("_Choose-a-Defect-Type")}
              <FormHelperText
                error={true}
                variant={"standard"}
                style={{ color: "#f44336" }}
              >
                {touched.defectType && errors.defectType}
              </FormHelperText>
            </FormLabel>
            <RadioGroup
              row
              value={values.defectType}
              onChange={(event) => {
                const data = setDefaultValues(event.target.value);
                setFieldValue("defectType", data.defectType);
                setFieldValue("riskType", data.risk);
                setFieldValue("priority", data.priority);
                setFieldValue("inRedFlag", data.isRedFlag);
              }}
            >
              {defectTypeOption.map((d) => (
                <FormControlLabel
                  value={d.value}
                  control={<Radio sx={radioButton} name={"defectType"} />}
                  label={
                    <Typography
                      variant="body2"
                      style={{
                        marginLeft: 4,
                      }}
                    >
                      {getLanguageBasedOption(d, scope.projectLanguageCode)}
                    </Typography>
                  }
                  labelPlacement="end"
                />
              ))}
            </RadioGroup>
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <Box className={"d-flex flex-column"}>
            <Box className={"d-flex"}>
              <FormLabel
                component="div"
                style={{
                  width: "40%",
                  marginTop: 10,
                  color:
                    touched.riskType && errors.riskType ? "#f44336" : "#00000099",
                }}
                error={touched.riskType && errors.riskType}
              >
                {getLabel("_Choose-Risk-Type")}
                <FormHelperText
                  error={true}
                  variant={"standard"}
                  style={{ color: "#f44336" }}
                >
                  {touched.riskType && errors.riskType}
                </FormHelperText>
              </FormLabel>
              <Box style={{ marginLeft: "auto" }}>
                <FormControlLabel
                  style={{ marginLeft: 0 }}
                  control={
                    <Checkbox
                      checked={values.inRedFlag}
                      name={"inRedFlag"}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={getTranslationVariantText("_AddToRedFlag", "subtitile", {
                    color: "#254a9a",
                    fontWeight: "bold",
                  })}
                  labelPlacement="end"
                />
              </Box>
            </Box>
            <Box>
              <RadioGroup
                row
                id="risk"
                value={values.riskType}
                onChange={(event) => {
                  const data = setDefaultRiskTypeValues(event.target.value);
                  setFieldValue("riskType", event.target.value);
                  setFieldValue("inRedFlag", data.isRedFlag);
                }}
              >
                {riskTypeOption.map((d) => (
                  <FormControlLabel
                    value={d.value}
                    control={<Radio sx={radioButton} name={"riskType"} />}
                    label={
                      <Typography
                        variant="body2"
                        style={{
                          marginLeft: 4,
                        }}
                      >
                        {getLanguageBasedOption(d, scope.projectLanguageCode)}
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                ))}
              </RadioGroup>
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <Box style={{ marginTop: 5 }} className={"d-flex flex-column"}>
            <FormLabel
              component="div"
              style={{
                width: "50%",
                marginTop: 10,
                color:
                  touched.priority && errors.priority
                    ? "#f44336"
                    : "#00000099",
              }}
              error={touched.priority && errors.priority}
            >
              {getLabel("_Choose-a-Priority-Type")}
              <FormHelperText
                error={true}
                variant={"standard"}
                style={{ color: "#f44336" }}
              >
                {touched.priority && errors.priority}
              </FormHelperText>
            </FormLabel>
            <RadioGroup
              row
              value={values.priority}
              onChange={(event) => {
                setFieldValue("priority", event.target.value);
                if (event.target.value !== null) {
                  if (event.target.value === "Multiple") {
                    setFieldValue("isMultiple", true);
                  } else {
                    setFieldValue("isMultiple", false);
                  }
                }
              }}
            >
              {priorityTypeOptions.map((d) => (
                <FormControlLabel
                  value={d.value}
                  control={<Radio sx={radioButton} name={"priority"} />}
                  label={
                    <Typography
                      variant="body2"
                      style={{
                        marginLeft: 4,
                      }}
                    >
                      {getLanguageBasedOption(d, scope.projectLanguageCode)}
                    </Typography>
                  }
                  labelPlacement="end"
                />
              ))}
            </RadioGroup>
          </Box>
        </Grid>
      </Box>

      {/* Dialog for low risk warning */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
      <AppBar className={"appBar"}>
            <Toolbar
              className={"dialog-toolbar"}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {getDialogBoxHeader("_warning")}
              <IconButton
                edge="start"
                color="inherit"
                id="create_project_close"
                onClick={(e) => {
                  setDialogOpen(false);
                  
                }}
                aria-label="close"
                style={{ marginLeft: "auto" }}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        <DialogContent>
        { getTranslationVariantText("_AddToRedFlag-Warning-1", "subtitile" )} "{ getTranslationVariantText("_Low", "subtitile", {fontWeight:"bold"} )}" { getTranslationVariantText("_AddToRedFlag-Warning-2", "subtitile" )}</DialogContent>
      </Dialog>
    </Box>
  );
}

DefectSection.propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  node: PropTypes.object,
};

export default DefectSection;
