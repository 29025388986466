import {
  Autocomplete,
  Box,
  InputAdornment,
  LinearProgress,
  ListItemText,
  OutlinedInput,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import CircularProgress from "@mui/material/CircularProgress";
import GuruEditor from "ckeditor5-custom-build/build/ckeditor";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import dayjs from "dayjs";
import debounce from "lodash.debounce";
import parse from "html-react-parser";
import {
  setUpdatedRows,
  resetState,
} from "./projectInformationTDDSlice.js";
import { getProjectUsers } from "../../../../../../api/userManagementApi.js";
import CustomButton from "../../../../../../components/buttons/CustomButton.js";
import CustomIconButton from "../../../../../../components/buttons/icons-buttons/CustomIconButton.js";
import PlacesAutocomplete from "../../../../../../components/locations/PlacesAutocomplete.js";
import { getVariantText, minimalEditorConfig, createAddress, getBorderColor, getBackground, getIntlTranslation } from "../../../../../../utils/helper.js";
import { promptDataSlice } from "../../../slice/dataSlice.js";
import { resetState as resetUserState } from "../../../../../component-bundle/users/usersSlice";
import { useSnackbar } from "notistack";

const AssetProjectInformation = ({ properties, onSave, reportType, onSaveSuccess }) => {
  const scope = useSelector((state) => state.container.scope);
  const state = useSelector((state) => state.projectInformationTDD);
  const [openMap, setOpenMap] = useState(false);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const openAsset = useSelector((state) => state.openAsset);
  const dispatch = useDispatch();
  const [itemList, setItemList] = useState([]);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [projectUsers, setProjectUsers] = useState([]);
  const loading = openDropDown && projectUsers.length === 0;
  const [users, setUsers] = useState("");
  const usersState = useSelector((state) => state.users);
  const { enqueueSnackbar } = useSnackbar();
  //const {assets} = useReportStructureContextHook();

  const [blockChange, setBlockChange] = useState({
    block: false,
    data: {},
    type: "",
  });
  const scrollParent = "scrollParent";

  useEffect(() => {
    dispatch(
      getProjectUsers({
        ident: scope.projectIdent,
      })
    );
  }, [dispatch, scope.projectIdent]);

  useEffect(() => {
    if (
      usersState.projectUsers.type === "FETCHED" &&
      usersState.projectUsers.data.length > 0
    ) {
      let temp = usersState.projectUsers.data.map((user) => {
        return {
          title: `${user.firstName} ${user.lastName}`,
          ...user,
          id: user.userId,
          display: `${user.firstName} ${user.lastName}`,
        };
      });
      setUsers(temp);
      dispatch(resetUserState());
    }
  }, [dispatch, usersState.projectUsers]);

  useEffect(() => {
    if (state.save.type === "SAVED" && !!state.save.data) {
      onSaveSuccess(state.save.data);
      state.save.messages.forEach((element) => {
        enqueueSnackbar(element, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      });
      dispatch(resetState());
    }
  }, [state.save, dispatch, onSaveSuccess, enqueueSnackbar]);

  const createPropertyStructure = useCallback(
    (properties) => {
      let propertiesList = properties
        ?.filter((f) => !!f.nodeName)
        .sort((a, b) => a.nodeOrder - b.nodeOrder)
        .map((el, index) => {
          const inputObj = el.labelTranslation.find(
            (e) => e.language === scope.projectLanguageCode
          );
          let temp = {
            ...el,
            updated: false,
            title: inputObj?.title || '', // Safe check for title
            userDtos: el.userDtos?.map((m) => ({
              ...m,
              title: `${m.firstName} ${m.lastName}`,
            })),
          };
          return { ...temp, original: temp };
        });
      return propertiesList;
    },
    [scope.projectLanguageCode]
  );

  useEffect(() => {
    if (properties?.length) {
      let structArr = createPropertyStructure(properties);
      setItemList(structArr);
      dispatch(resetState());
    } else {
      setItemList([]);
      dispatch(resetState());
    }
  }, [createPropertyStructure, properties, dispatch]);

  const getPropertyName = (property) => {
    const inputObj = property?.labelTranslation.find(
      (e) => e.language === scope.projectLanguageCode
    );
    return getVariantText(inputObj?.title || property?.name, "caption", {
      fontWeight: "bold",
      color: "#254a9a",
      margin: 5,
      textTransform: "uppercase",
    });
  };

  const addUpdatedValue = useCallback(
    (val) => {
      let temp = state.updatedRows;
      let found = temp?.find((f) => f.nodeIdent === val.nodeIdent);
      if (!found) {
        let tempObj = {
          nodeIdent: val.nodeIdent,
          labelTranslation: val.labelTranslation,
          updated: val.updated,
        };
        let restArry = temp?.filter((f) => {
          if (
            f.nodeIdent === val.nodeIdent &&
            f.subAssetIdent === val.subAssetIdent
          ) {
            return false;
          } else {
            return true;
          }
        });
        dispatch(setUpdatedRows([...restArry, { ...tempObj }]));
        dispatch(promptDataSlice([...restArry, { ...tempObj }]));
      }
    },
    [dispatch, state.updatedRows]
  );

  const updateValuesForItemList = useCallback(
    (nodeIdent, updatedVal) => {
      let temp = itemList.filter((f) => {
        if (f.nodeIdent === nodeIdent) {
          return false;
        } else {
          return true;
        }
      });

      setItemList(
        [...temp, updatedVal].sort((a, b) => a.nodeOrder - b.nodeOrder)
      );
      if (updatedVal.updated) {
        addUpdatedValue(updatedVal);
      }
    },
    [addUpdatedValue, itemList]
  );

  const handleTextChange = useCallback(
    (val, name, nodeIdent) => {
      let tempObj = itemList.find((f) => f.nodeIdent === nodeIdent);
      let isUpdated = tempObj.nodeValue !== val;
      const updatedVal = {
        ...tempObj,
        nodeValue: val,
        updated: isUpdated,
      };
      if (isUpdated) {
        updateValuesForItemList(nodeIdent, updatedVal);
      }
    },
    [itemList, updateValuesForItemList]
  );

  const handleEditorChanges = useCallback(
    (val, name, nodeIdent) => {
      let tempObj = itemList.find((f) => f.nodeIdent === nodeIdent);
      let isUpdated = tempObj.nodeValue !== val;
      const updatedVal = {
        ...tempObj,
        nodeValue: val,
        updated: isUpdated,
      };
      if (isUpdated) {
        updateValuesForItemList(nodeIdent, updatedVal);
      }
    },
    [itemList, updateValuesForItemList]
  );

  const debouncedTextChangeHandler = useMemo(
    () => debounce(handleTextChange, 300),
    [handleTextChange]
  );

  const handleUserSelectionChanges = useCallback(
    (val, name, nodeIdent) => {
      let tempObj = itemList.find((f) => f.nodeIdent === nodeIdent);

      const updatedVal = {
        ...tempObj,
        userDtos: val,
        updated: true,
      };
      updateValuesForItemList(nodeIdent, updatedVal);
    },
    [itemList, updateValuesForItemList]
  );

  const handleAddressChange = (val, name, nodeIdent) => {
    let tempObj = itemList.find((f) => f.nodeIdent === nodeIdent);
    let isUpdated = tempObj.nodeValue !== val.toString();
    const updatedVal = {
      ...tempObj,
      nodeValue: val.toString(),
      updated: isUpdated,
    };
    if (isUpdated) {
      updateValuesForItemList(nodeIdent, updatedVal);
    }
  };

  const handleDateChange = (val, name, nodeIdent) => {
    let tempObj = itemList.find((f) => f.nodeIdent === nodeIdent);
    let isUpdated = tempObj.nodeValue !== val;
    const updatedVal = {
      ...tempObj,
      nodeValue: val,
      updated: isUpdated,
    };
    if (isUpdated) {
      updateValuesForItemList(nodeIdent, updatedVal);
    }
  };

  const handleSave = () => {
    if (typeof onSave === "function") {
      let tempObject = {
        type: "assetProjectInformationDto",
        assetIdent: openAsset.ident,
      };
      itemList.forEach((item) => {
        if (item.nodeType === "author_user") {
          if (item?.userDtos?.length > 0) {
            let temp = item.userDtos.map((u) => ({
              ...u,
              type: "projectUserDto",
            }));
            tempObject = { ...tempObject, [item.nodeKey]: temp };
          } else {
            tempObject = { ...tempObject, [item.nodeKey]: [] };
          }
        } else if (
          item.nodeType === "date" ||
          item.nodeType === "date-future"
        ) {
          tempObject = {
            ...tempObject,
            [item.nodeKey]: new Date(item.nodeValue),
          };
        } else {
          tempObject = { ...tempObject, [item.nodeKey]: item.nodeValue };
        }
      });
      onSave(tempObject);
    }
  };


  const getInputFields = (
    value,
    disabled,
    ident,
    name,
    type,
  ) => {
    let nodeRender = <></>;
    if (type.includes("_user")) {
      nodeRender = (
        <Autocomplete
          multiple
          open={openDropDown}
          onOpen={() => {
            setOpenDropDown(true);
            if (projectUsers.length === 0) {
              dispatch(
                getProjectUsers({
                  ident: scope.projectIdent,
                })
              );
            }
          }}
          onClose={() => {
            setOpenDropDown(false);
          }}
          id={name}
          value={!!value?.length > 0 ? value : []}
          options={users}
          getOptionLabel={(option) => option.title || null}
          onChange={(event, newValue) => {
            handleUserSelectionChanges(newValue, name, ident);
          }}
          isOptionEqualToValue={(option, value) =>
            option.userId === value.userId
          }
          size="small"
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={name}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      );
    } else if (type === "textarea") {
      nodeRender = (
        <>
          <Box
            id={`box_${name}`}
            className="d-flex g-text-editor align-items-center justify-content-center"
            style={{ minHeight: "200px", width: "100%" }}
            tabIndex={-1}
          >
            <CKEditor
              id={`box_${name}_${ident}`}
              editor={GuruEditor.ClassicEditor}
              config={minimalEditorConfig}
              disabled={disabled}
              data={value || ''} // Safe check for value
              onBlur={(event, editor) => {
                handleEditorChanges(editor.getData(), name, ident);
              }}
            />
          </Box>
        </>
      );
    } else if (type === "number") {
      nodeRender = (
        <>
          <TextField
            style={{ width: "100%", margin: 2 }}
            id={name}
            key={name}
            disabled={disabled}
            defaultValue={value}
            type={type}
            onChange={(e) =>
              debouncedTextChangeHandler(e.target.value, name, ident)
            }
            aria-describedby={`component-${name}`}
            inputProps={{
              "aria-label": `${name}`,
            }}
            variant="outlined"
            size="small"
            dense="true"
          />
        </>
      );
    } else if (type === "date" || type === "date-future") {
      let date = value;
      if (value === " ") {
        date = null;
      }
      nodeRender = (
        <>
          <Box className="d-flex align-items-center justify-content-center w-100">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={!!date ? dayjs(new Date(date)) : null}
                style={{ margin: 5, width: "100%" }}
                disabled={disabled}
                autoOk
                format="DD/MM/YYYY"
                disableFuture={type === "date" ? true : false}
                onChange={(date) => {
                  if (!!date) {
                    handleDateChange(date, name, ident);
                  } else {
                    handleDateChange(null, name, ident);
                  }
                }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    size: "small",
                    style: { width: "100%" },
                    helperText: null,
                    error: null,
                  },
                  inputAdornment: {
                    position: "start",
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        </>
      );
    } else if (type === "date-year") {
      let year = value;
      if (value === " ") {
        year = null;
      }
      nodeRender = (
        <>
          <Box className="d-flex align-items-center justify-content-center w-100">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={!!year ? dayjs(new Date(year, 1, 1)) : null}
                style={{ marginTop: 5, width: "100%" }}
                disabled={disabled}
                autoOk
                autoFocus
                views={["year"]}
                onChange={(value) => {
                  if (!!value) {
                    handleDateChange(value.year(), name, ident);
                  } else {
                    handleDateChange(null, name, ident);
                  }
                }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    size: "small",
                    style: { width: "100%" },
                    helperText: null,
                    error: null,
                  },
                  inputAdornment: {
                    position: "start",
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        </>
      );
    } else if (type === "address") {
      nodeRender = (
        <>
          <Box className="d-flex align-items-center justify-content-center w-100">
            <OutlinedInput
              style={{ width: "100%", marginTop: 5 }}
              id={name}
              key={`${name}_${value.split(" ")[0]}`}
              disabled={disabled}
              defaultValue={value}
              multiline
              maxRows={4}
              variant="outlined"
              size="small"
              dense="true"
              onChange={(e) =>
                debouncedTextChangeHandler(e.target.value, name, ident)
              }
              endAdornment={
                <InputAdornment position="end">
                  <CustomIconButton
                    id="custom_address_search"
                    handleClick={(e) => {
                      setOpenMap({
                        open: true,
                        anchorEl: e.currentTarget,
                      });
                    }}
                    icon={<TravelExploreIcon style={{ color: "#1f88db" }} />}
                    tooltip={{
                      title: "Click to search address",
                    }}
                    aria-label="toggle visibility"
                    aria-describedby="googleMaps"
                    aria-haspopup="true"
                  />
                </InputAdornment>
              }
            />
            <Popover
              id={"googleMaps"}
              open={openMap.open}
              anchorEl={openMap.anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                style: { width: "40%" },
              }}
              onClose={() =>
                setOpenMap({
                  open: false,
                  anchorEl: null,
                })
              }
            >
              <PlacesAutocomplete
                setLat={setLat}
                setLng={setLng}
                setGetAddress={(val) => {
                  let address = createAddress(val);
                  handleAddressChange(address, name, ident);
                  setOpenMap({
                    open: false,
                    anchorEl: null,
                  });
                }}
                fullAddress={value}
              />
            </Popover>
          </Box>
        </>
      );
    } else if (type === "text-html") {
      nodeRender = <div className="text-html">{parse(value)}</div>;
    } else if (type === "text" && disabled) {
      nodeRender = <Typography>{value}</Typography>;
    } else {
      nodeRender = (
        <>
          <TextField
            style={{ width: "100%" }}
            id={`${name}_${ident}`}
            key={`${name}_${ident}`}
            disabled={disabled}
            defaultValue={value}
            type={type}
            multiline
            maxRows={type === "text-multiline" ? 5 : 2}
            onChange={(e) =>
              debouncedTextChangeHandler(e.target.value, name, ident)
            }
            aria-describedby={`component-${name}`}
            inputProps={{
              "aria-label": `${name}`,
            }}
            variant="outlined"
            size="small"
            dense="true"
          />
        </>
      );
    }

    return (
      <>
        <Box className="w-100 d-flex align-items-baseline">
          <Box style={{ width: "95%" }}>{nodeRender}</Box>
        </Box>
      </>
    );
  };

  const renderTableStructure = () => {
    return (
      <>
        <table className="custom-table custom-scrollBar">
          <tbody>
            {itemList?.map((input, index) => {
              let isUpdated = state.updatedRows?.some(
                (f) => f.nodeIdent === input.nodeIdent
              );
              return (
                <tr
                  id={`${scrollParent}_${input.nodeIdent}`}
                  key={`${scrollParent}_${input.nodeIdent}`}
                  style={{
                    borderLeft: getBorderColor(isUpdated),
                    backgroundColor: getBackground(isUpdated),
                    padding: 8,
                  }}
                >
                  <td
                    id={input.nodeIdent}
                    style={{
                      wordWrap: "break-word",
                      width: "30%",
                      fontSize: "12px",
                      verticalAlign: "baseline",
                    }}
                  >
                    <div
                      className="sticky-text p-1"
                     
                    >
                      {getPropertyName(input)}
                    </div>
                  </td>
                  <td>
                    {getInputFields(
                      input.nodeType.includes("_user")
                        ? input.userDtos
                        : input.nodeValue,
                      input.disabled,
                      input.nodeIdent,
                      input.nodeName,
                      input.nodeType,
                      input.commentsAllowed,
                      input.nodeKey
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };
  return (
    <div className="d-flex flex-column h-100 w-100 justify-content-center p-1">
      {!!itemList && itemList.length > 0 ? (
        <>
          <Box
            className="d-flex flex-column w-100 justify-content-center"
            style={{ height: "calc(100% - 30px)" }}
          >
            <div
              id="building-profile-conatiner"
              className="d-flex h-100 w-100 justify-content-center"
            >
              <Box className="d-flex h-100 w-100 justify-content-center">
                {renderTableStructure()}
              </Box>
            </div>
          </Box>
          <div
            style={{
              width: "100%",
              overflowY: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 5,
              marginTop: 5,
              position: "sticky",
              bottom: 0,
              zIndex: 1500,
              backgroundColor: "#ffff",
            }}
          >
            <CustomButton
              id={"save_project-information"}
              buttonText="_save"
              variant="button"
              className="g-btn-primary"
              style={{
                margin: 2,
                height: 40,
                width: "100%",
                padding: 5,
              }}
              tooltip={{
                title: getIntlTranslation("tooltip.save"),
              }}
              handleClick={(e) => {
                handleSave();
              }}
            />
          </div>
        </>
      ) : (
        <>
          <Box className="w-100">
            <LinearProgress />
          </Box>
        </>
      )}
    </div>
  );
};
export default AssetProjectInformation;
