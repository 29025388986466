import React, { useCallback, useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import GuruEditor from "ckeditor5-custom-build/build/ckeditor";
import { Box } from "@mui/material";
import { normalEditorConfig } from "../../../../utils/helper";
import { useIntl } from "react-intl";

const TextEditorInput = ({ id, node, disabled = false, handleChange }) => {
  const intl = useIntl();
  const [editorLoaded, setEditorLoaded] = useState(false);
  const editorRef = useRef();
  const [isDirty, setIsDirty] = useState(false);
  const [firstChangeTriggered, setFirstChangeTriggered] = useState(false);
  const localNodeRef = useRef(node);
  
  const handleEditorReady = (editor) => {
    editorRef.current = editor;
    setEditorLoaded(true);
  };

  localNodeRef.current = node;
  
  const handleEditorChange = useCallback((editor) => {
    if (editorLoaded) {
      if (!firstChangeTriggered) {
        setFirstChangeTriggered(true);
        handleChange(editor.getData(),localNodeRef.current,true);
      }
      setIsDirty(true);
    }
  },[editorLoaded, firstChangeTriggered, handleChange]);

  return (
    <Box
      id={`box-${id}`}
      className="d-flex g-text-editor align-items-center justify-content-center"
      style={{ minHeight: "200px", width: "100%" }}
      tabIndex={-1}
      key={`box-${id}`}
    >
      <CKEditor
        id={`editor-${id}`}
        key={`editor-${id}`}
        ref={editorRef}
        editor={GuruEditor.ClassicEditor}
        onReady={(editor) => handleEditorReady(editor)}
        config={{ ...normalEditorConfig,placeholder: intl.formatMessage({ id:"_text-editor-placeholder"}) }}
        onChange={(event, editor) => handleEditorChange(editor)}
        disabled={disabled}
        data={node.value}
        onBlur={(event, editor) => {
          handleChange(editorRef.current.getData(), node, isDirty);
        }}
      />
    </Box>
  );
};

export default TextEditorInput;
