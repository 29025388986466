import { useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import SubAssetSwitcher from "../../../../component-bundle/assetSwitcher/SubAssetSwitcher";
import { getCategoryNavlinks } from "../../../api/keyFindingApi";
import { resetState } from "../keyFindingSlice";
import KeyFindingsContainer from "./KeyFindingsContainer";
import FilterOptions from "./FilterOptions";
import InternalPrompt from "../../../../../components/prompt/InternalPrompt";
import { setOpenAsset } from "../../asset-management/asset/OpenAssetSlice";
import {getWarningText,getTranslationVariantText } from "../../../../../utils/helper";
import NoAssets from "../../../../component-bundle/NoAssets";

const CategoriesSectionStyle = {
  maxHeight: `calc(100% - 50px)`,
  height: `calc(100% - 50px)`,
  overflow: "hidden",
};
function KeyFindingsDataWrapper(props) {
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const state = useSelector((state) => state.tddKeyFinding);
  const dispatch = useDispatch();
  const [categoriesTab, setCategoriesTab] = useState([]);
  const [navlinksData, setNavLinksData] = useState([]);
  const [blockSubAssetChange, setBlockSubAssetChange] = useState({
    block: false,
    newSubAsset: {},
  });
  const readOnlystate = useSelector((state) => state.readOnly);

  useEffect(() => {
    if (!!openAsset?.subAssetIdent) {
      dispatch( 
        getCategoryNavlinks({
          projectIdent: scope.projectIdent,
          subAssetIdent: openAsset.subAssetIdent,
        })
      );
    }
  }, [ dispatch, scope.projectIdent, openAsset]);

  useEffect(() => {
    if (state.navLinks.type === "SUCCESS" && !!state.navLinks.data) {
      let navlinks = state.navLinks.data?.navLinks;
      if (!!navlinks && navlinks.length) {
        let temp = navlinks.map((m) => {
          return {
            tab: {
              tabName: m.title.find(
                (f) => f.language === scope.projectLanguageCode
              ),
              key: m.key,
              ident: m.ident,
              nodeType: m.nodeType,
              parent: m.parentId,
            },
            nodeType: m.nodeType,
            ident: m.ident,
            key: m.key,
            children: m.children,
          };
        });
        setNavLinksData(temp);
        setCategoriesTab(
          temp.map((m) => ({ ...m.tab, name: m.tab.tabName.title }))
        );
      }
      dispatch(resetState());
    }
  }, [dispatch, state, scope.projectLanguageCode]);

  const setBlockState = (val) => {
    setBlockSubAssetChange({
      block: true,
      newSubAsset: val,
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          marginBottom: 5,
          height:50 
        }}
      >
        <div style={{ width: "20%", height:50 }}>
          <SubAssetSwitcher
            dirtyState={state.updatedRows.length > 0}
            setBlockState={(val) => setBlockState(val)}
          />
        </div>
        {!!openAsset.ident && 
        <div style={{ width: "80%", height:50 }}>
          <FilterOptions />
        </div>
        }
      </div>
      {!!openAsset.ident ? (
      <div style={CategoriesSectionStyle}>
        <KeyFindingsContainer tabs={categoriesTab} navLinks={navlinksData} />
      </div>
      ):<NoAssets />}
      {!readOnlystate.isReadOnly && (
        <InternalPrompt
          when={blockSubAssetChange.block}
          stayText="_back"
          saveText="Save And Leave"
          leaveText="_continue"
          onLeave={() => {
            dispatch(setOpenAsset(blockSubAssetChange.newSubAsset));
            setBlockSubAssetChange({
              block: false,
              newSubAsset: {},
            });
          }}
          onStay={() =>
            setBlockSubAssetChange({
              block: false,
              newSubAsset: {},
            })
          }
          warningHeader={getTranslationVariantText("_leave warning", "body1")}
          message={getWarningText(state.updatedRows)}
        />
      )}
    </>
  );
}

KeyFindingsDataWrapper.propTypes = {};

export default KeyFindingsDataWrapper;
