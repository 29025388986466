import React from "react";
import {
  Box,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from "@mui/material";
import { useReportStructureContextHook } from "./ReportStructureContext";
import { dataRender } from "./reportItemRender";


function breakIndex(index) {
  const cleanIndex = index.endsWith('.') ? index.slice(0, -1) : index;
  const parts = cleanIndex.split('.');
  const results = [];
  for (let i = 0; i < parts.length; i++) {
      if (i === 0) {
          results.push(parts[i]);
      } else {
          results.push(parts.slice(0, i + 1).join('.') + '.');
      }
  }

  return results;
}

const TreeNode = ({ node, index, selectedIndices, depth = 0}) => {
  const { reportType, selectedItem } = useReportStructureContextHook();
  if(!!selectedIndices[depth]){
    index = selectedIndices[depth];
  }
 
  return (
    <div key={node.id}>
      <ListItem
        className="p-0 d-flex flex-column w-100"
        key={node.id}
        id={node.id}
      >
        {dataRender({ ...node, index: index },reportType, selectedItem)}
      </ListItem>
      <Box></Box>
      {node.children?.length > 0 && (
        <List className="p-0">
          {node?.children?.map((child, i) => {
            index = `${index}`.replace(/\.$/, "");
            return (
              <TreeNode
                index={`${index}.${i + 1}.`}
                node={{ ...child, parentId: node.id }}
                selectedIndices={selectedIndices}
                depth = {depth + 1 }
              />
            );
          })}
        </List>
      )}
    </div>
  );
};

const ContentContainer = (props) => {

  const { loading, documentItems } = useReportStructureContextHook();

  return (
    <CardContent
      className="custom-scrollBar p-1"
      style={{
        width: "100%",
        overflowY: "auto",
        overflowX: "auto",
        height: "100%",
      }}
      id={`data-container`}
    >
      {loading ? (
        <List>
          {[1, 2, 3, 4, 5].map(index => (
            <ListItem key={index}>
              <ListItemText>
                <Skeleton variant="text" />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      ) :(
      <List id={`list-data-container`} className="p-1">
        {documentItems?.children?.map((m, index) => {
          const chapterNumbering = breakIndex(m.selectedIndex);
          return (
            <TreeNode key={`${m.id}-${index}`} node={m} index={index+1} selectedIndices={chapterNumbering}/>
          );
        })}
      </List>)
}
    </CardContent>
  );
};

ContentContainer.propTypes = {};

export default ContentContainer;
